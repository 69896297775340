//@font-face {
//  font-family: 'PTSerifItalic';
//  src: url('../fonts/PTSerifItalic.eot');
//  src: url('../fonts/PTSerifItalic.eot?#iefix') format('embedded-opentype'),
//  url('../fonts/PTSerifItalic.woff') format('woff'),
//  url('../fonts/PTSerifItalic.ttf') format('truetype');
//  font-weight: normal;
//  font-style: normal;
//
//}
@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-Italic.woff2') format('woff2'),
  url('../fonts/SegoeUI-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-LightItalic.woff2') format('woff2'),
  url('../fonts/SegoeUI-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI Semilight';
  src: url('../fonts/SegoeUI-Semilight.woff2') format('woff2'),
  url('../fonts/SegoeUI-Semilight.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-BoldItalic.woff2') format('woff2'),
  url('../fonts/SegoeUI-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI Semilight';
  src: url('../fonts/SegoeUI-SemilightItalic.woff2') format('woff2'),
  url('../fonts/SegoeUI-SemilightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUIBlack-Italic.woff2') format('woff2'),
  url('../fonts/SegoeUIBlack-Italic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-SemiboldItalic.woff2') format('woff2'),
  url('../fonts/SegoeUI-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-Bold.woff2') format('woff2'),
  url('../fonts/SegoeUI-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI.woff2') format('woff2'),
  url('../fonts/SegoeUI.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-Light.woff2') format('woff2'),
  url('../fonts/SegoeUI-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUIBlack.woff2') format('woff2'),
  url('../fonts/SegoeUIBlack.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Segoe UI';
  src: url('../fonts/SegoeUI-Semibold.woff2') format('woff2'),
  url('../fonts/SegoeUI-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

