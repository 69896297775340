// Sass Breakpoint Mixin
@mixin breakpoint($point) {
  @if $point == p576 {
    @media (min-width: 576px) {
      @content;
    }
  } @else if $point == p768 {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == p992 {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $point == p1200 {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $point { // allows custom breakpoints
    @media (min-width: $point) {
      @content;
    }
  }
}

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss ,only before @import bootstrap variables!"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

$gutter: $grid-gutter-width;
@import 'fonts';
//@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';


body {
  min-width: 320px;
  height: 100%;
  font-size: 16px;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
}

img, svg {
  display: block;
}

.bg-layout-cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

/* input-placeholder */
input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

textarea:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input::-ms-clear {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.container {
  width: 100%;
  max-width: 1550px;
  padding-right: 15px;
  padding-left: 15px;
  margin: 0 auto;
}

html {
  height: 100%;
}
body {
  width: 100%;
  font-family: 'Segoe UI';
  color: #000000;
  font-size: 16px;
  margin:  0 auto;
  max-width: 1920px;
  height: 100%;
}
.wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.content {
  flex: 1 0 auto;
}
h2.title {
  font-size: 70px;
  font-weight: 700;
  line-height: 84px;
  text-transform: uppercase;
}

h3.title {
  font-size: 50px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
}
h4.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 29px;
  text-transform: uppercase;
}
h5.title {
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
}

img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

/*-----header---------*/
.header-wrapper {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 34px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.logo {
  width: 100%;
  max-width: 278px;
  height: 78px;
}

.social {
  width: 100px;
  display: flex;
  justify-content: space-between;

  .social-item {
    width: 21px;
    height: 21px;

    svg {
      height: 21px;
      width: auto;
      fill: #666666;
    }
  }

  .instagram {
    svg:hover {
      fill: #8a2be2;
    }
  }

  .facebook {
    svg:hover {
      fill: #4040ff;
    }
  }

  .youtube {
    svg:hover {
      fill: #e61d19;
    }
  }

}

.phone-number {
  position: relative;
  max-width: 390px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 32px;

  a {
    color: #020202;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;

    &:last-child {
      margin-left: 14px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: url(../img/svg/call-answer.png) no-repeat 50% 50%;

  }
}

.btn {
  width: 276px;
  height: 44px;
  background-color: #ff6431;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

@media all and (max-width: 1100px) {
  .header-wrapper {
    justify-content: space-around;
  }
  .logo, .social, .phone-number, .button-consultation {
    margin: 0 14px;
  }
  .button-consultation {
    margin-top: 20px;
  }
}

@media all and (max-width: 881px) {
  .button-consultation, .phone-number {
    margin-top: 20px;
  }
}

@media all and (max-width: 576px) {
  .button-consultation, .phone-number {
    display: none;
  }
  .social {
    margin-top: 20px;
  }
}

/*----menu---------------*/
.menu-wrapper {
  width: 100%;
  height: 62px;

  .menu {
    width: 100%;
    display: flex;
    border-bottom: 3px solid #ff6431;
    height: 62px;
    justify-content: space-between;
    align-items: center;
    .menu-item.active {
      color: #ffffff;
      background-color: #ff6431;
    }
    .menu-item {
      position: relative;
      color: #000000;
      width: auto;
      padding: 0 54px;
      font-size: 16px;
      line-height: 62px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;

      &:hover {
        color: #ffffff;

        background-color: #ff6431;
      }

      ul {
        display: none;
        width: 100%;
        padding-left: 22px;
        padding-right: 10px;
        padding-top: 30px;
        padding-bottom: 26px;
        position: absolute;
        box-shadow: 0 10px 10px rgba(0, 0, 1, 0.25);
        background-color: #ffffff;
        left: 0px;
        z-index: 7;
        top: 100%;
        text-align: left;
        background: #fff;

        li {
          width: 100%;
          margin-bottom: 20px;
          color: #000000;
          font-size: 17px;
          text-transform: none;
          font-weight: 400;
          line-height: 20px;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -24px;
          border: 11px solid transparent;
          border-bottom: 14px solid #ffffff;
        }

      }

    }




  }

  .menuFlex {
    display: flex;
  }

  .menuBlock {
    display: block !important;
  }

  /*---menu burger---*/
  .menuBurger {
    display: none;
    height: auto;
  }

  .menu-btn {
    display: block;
    width: 33px;
    height: 33px;
    background-color: transparent;
    position: relative;
  }

  .menu-btn span {
    top: 10px;
    right: 0;

  }

  .menu-btn span,
  .menu-btn span::before,
  .menu-btn span::after {
    position: absolute;
    width: 40px;
    height: 6px;
    background-color: #ff6431;
  }


  .menu-btn span::before,
  .menu-btn span::after {
    content: '';
    display: block;
    transition: 0.2s;
  }

  .menu-btn span::before {
    transform: translateY(-14px);
  }

  .menu-btn span::after {
    transform: translateY(14px);
  }


  .menu-btn_active span:before {
    background: #ffffff;
    transform: rotate(-45deg);
  }

  .menu-btn_active span {
    height: 0;
  }

  .menu-btn_active span:after {
    background: #ffffff;
    transform: rotate(45deg);
  }
}

@media all and (max-width: 1480px) {
  .menu-wrapper {
    .menu {
      .menu-item {
        padding: 0 34px;
        font-size: 16px;
      }
    }
  }
}

@media all and (max-width: 1232px) {
  .menu-wrapper {
    .menu {
      .menu-item {
        padding: 0 22px;
        font-size: 14px;

        li {
          font-size: 14px;
        }
      }
    }
  }
}

@media all and (max-width: 992px) {
  .menu-wrapper {
    position: absolute;
    top: 102px;
    right: 0;

    .menuBurger {
      position: absolute;
      top: 50%;
      padding-right: 15px;
      transform: translateY(-50%);
      right: 0;
      z-index: 4;
      display: inline-block;
    }
  }
  .menu-wrapper .menu {
    display: none;
    background: #ff6431;
    width: 100%;
    height: auto;
    z-index: 3;
    right: 0;
    top: 0;
    padding: 20px;
    padding-top: 40px;
    position: absolute;


    .menu-item {
      display: block;
      text-align: left;
      line-height: 46px;
      font-size: 16px;

      ul {
        width: 60%;
        z-index: 4;
        left: 30%;

        &:after {
          left: 10%;
        }

        li {
          margin-bottom: 15px;
        }
      }

    }

  }
  .header nav .menu .menu-item ul {
    top: 30px;

  }
}
@media all and (max-width: 881px) {
  .menu-wrapper {
    position: absolute;
    top: 20px;
    right: 0;
  }
}
@media all and (max-width: 752px) {
  .menu-wrapper {
    position: absolute;
    top: 95px;
    right: 0;
  }
}

@media all and (max-width: 463px) {
  .menu-wrapper .menu {
    .menu-item {
      ul {
        width: 80%;
        z-index: 4;
        left: 20%;
      }
    }
  }

}
/*/////////////////////////////*/

/*-----footer------------------*/
footer {
  .header-wrapper {
    padding-top: 42px;
  }

  width: 100%;
  max-width: 1920px;
  height: 100%;
  flex: 0 0 auto;
  .send-request {
    max-height: 580px;
    height: 100%;
  }
  form {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 67% ;
    z-index: 3;
    padding-top: 58px;
    padding-bottom: 54px;
    text-align: center;
    width: 100%;
    max-width: 436px;
    height: auto;
    background: #ffffff;

    h4.title {
      margin-bottom: 22px;
    }

    .text {
      display: inline-block;
      margin-bottom: 26px;
      color: #333333;
      font-size: 14px;
      font-weight: 400;
    }

    .input-wrapper {
      margin-bottom: 16px;

      input {
        width: 276px;
        height: 44px;
        text-align: center;
        background-color: #ededed;
        color: #666666;
        font-size: 16px;
        font-weight: 400;
      }
    }

    button {
      margin-bottom: 25px;
    }
    .confidentiality {
      color: #999999;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.map_container{
  position: relative;
  overflow:hidden;
  padding-bottom:30.25%;
  padding-top: 0;
  position:relative;
  height:0;
}
.map_container iframe{
  left:0;
  top:0;
  height:100%;
  width:100%;
  position:absolute;
}
@media all  and (max-width: 1300px) {
  .map_container {
    min-height: 500px;
  }
  footer form {
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

/*/////////////////////////////*/
/*------main-------------------*/
.first-screen {
  position: relative;
  padding-top: 65px;
  height: auto;
  padding-bottom: 127px;
  &:after {
    content: '';
    z-index: -2;
    position: absolute;
    top:100%;
    left:100%;
    transform: translate(-100%, -100%);
    width: 1089px;
    height: 712px;
    background-color: #ededed;
  }
  .wrapper-content {
    position: relative;
    padding: 118px 0;
  }
  .title-wrapper {
    max-width: 646px;
    width: 100%;
    margin-bottom: 40px;
  }
  .list-description {
    margin-bottom: 40px;
    .list-item {
      margin-bottom: 12px;
      position: relative;
      color: #000000;
      font-size: 24px;
      line-height: 36px;
      font-weight: 400;
      padding-left: 50px;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        width: 36px;
        height: 36px;
        transform: translateY(-50%);
        left:0;
        background:  url(../img/svg/list-marker1.png);

      }
    }
  }
  .btn {
    position: relative;
    width: 305px;
    height: 50px;
    line-height: 50px;
    padding-right: 18px;
    &:after {
      content: '';
      width: 12px;
      height: 22px;
      top:50%;z-index: 2;
      transform: translateY(-50%);
      right: 16px;
      position: absolute;
      background: url(../img/svg/arrow-right.png);
    }
  }
}

.banner-block {
  z-index: -1;
  position: absolute;
  max-width: 1069px;
  width: 100%;
  max-height: 710px;
  height: 100%;
  top:0;
  right: 0;
  img {
    object-fit: cover;
    height: 100%;
    width: auto;
  }
  .count-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top:100%;
    left:100%;
    transform: translate(-100%, -100%);
    width: 94px;
    height: 91px;
    background-color: #ff6431;
    color: #ffffff;
    .number {
      font-weight: 700;
      font-size: 30px;
    }
    .all-number {
      font-size: 16px;
    }
  }
}
.our-service {
  position: relative;
  width: 100%;
  height: auto;
  padding: 158px 0;
  &:before {
    content: '';
    position: absolute;
    top:50%;
    left: 0;
    z-index: -2;
    transform: translateY(-50%);
    max-width: 703px;
    width: 100%;
    height: 361px;
    background-color: #ededed;
  }
  .wrapper-content {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    justify-content: space-between;
    align-items: center;
  }
  .title-wrapper {
    width: 190px;
    margin-right: 20px;
  }
  .service-block {
    width: 100%;
    max-width: 1210px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .service-item {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      margin-bottom: 32px;
      font-size: 24px;
      font-weight: 400;
      min-width: 276px;
      min-height: 276px;
      background-color: #000000;
      .title-service {
        text-align: center;
        position: absolute;
        width: 100%;
        top:50%;
        left:50%;
        transform: translate(-50%,-50%);
      }
    }

  }

}
.solutions {
  position: relative;
  &:after {
    width: 408px;
    height: 766px;
    content: '';
   z-index: -1;
    position: absolute;
    bottom: -50px;
    right: 0;
    background-color: #ededed;
  }
  .wrapper-content {
    padding-top: 0;
    padding-bottom: 60px;
  }
.title-wrapper {
  margin-bottom: 74px;
  width: 100%;
  h3.title {
    text-align: center;
  }
}
  .solutions-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .solutions-item {
    max-width: 486px;
    margin-bottom: 46px;
    width: 100%;
  }
  .solutions-img {
    width: 100%;
    height: auto;
    img {
      object-fit: contain;
    }
  }
  .title-solutions {
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    height: 90px;
    line-height: 90px;
  }
}
.repair-stages {
  width: 100%;
  background: transparent;
  padding-bottom: 105px;
  .wrapper-content {
    padding: 0;
  }
  .title-wrapper {
    margin-bottom: 60px;
  }
  h3.title {
    text-align: left;
  }
  .repair-block {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 -5px;

  }
  .repair-item {
    position: relative;
    width: 100%;
    margin: 0 5px;
    margin-bottom: 10px;
    padding-left: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 20px;
    max-width: 371px;
   height: 242px;
    background-color: #ff6431;
  }
  .repair-stage {
    position: absolute;
    top: 22px;
    right: 40px;
    opacity: 0.1;
    color: #ffffff;
    font-size: 78px;
    font-weight: 700;
  }
  .number {
    width: auto;
    margin-bottom: 60px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 700;
  }
  .title-repair {
    .title {
      color: #ffffff;
      font-family: "Segoe UI";
      font-size: 24px;
      font-weight: 400;
    }
  }
}
.best {
  .title-wrapper {
    margin-bottom: 66px;
    h3.title {
      text-align: center;
    }
  }
  padding-top: 120px;
  padding-bottom: 62px;
  background: url(../img/svg/best-img.png) no-repeat 50% 50%;
  background-size: cover;
  .best-block {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    justify-content: center;
  }
  .best-item {
    width: 390px;
    margin: 0 25px;
    margin-bottom: 75px;
    height: auto;
    .number {
      display: flex; align-items: flex-end;
      justify-content: flex-start;
      position: relative;
      width: 108px;
      margin-bottom: 36px;
      height: 82px;
      font-size: 70px;
      font-weight: 700;
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background-color: #ff6431;
      }
    }
    .title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}
.cost {
  padding: 86px 0;
  .title-wrapper {
    margin-bottom: 26px;
    h5.title {
      text-align: left;
    }

  }
  .cost-block {
    width: 100%;
    height: auto;
    p {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      &:last-child {

      margin-bottom: 0;
      }
    }
  }
}
@media all and (max-width: 1550px) {
  .repair-stages {
    .repair-block {
      justify-content: space-around;
    }
  }
}
@media all and (max-width: 1492px) {
  .solutions .solutions-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
}
@media all and (max-width: 1450px) {
  .solutions .solutions-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .our-service .title-wrapper {
    width: 100%;
    margin-bottom: 70px;
    margin-right: 0;
    text-align: center;
    h3.title {
      width: 100%;
    }
  }
  .our-service .service-block {
    max-width: 100%;
    justify-content: space-around;
  }
}
@media all and (max-width: 1200px) {
  .our-service {
    padding: 40px;
  }
  .cost {
    padding: 40px 0px;
  }
  .best {
    padding: 40px;
  }
  .repair-stages {
    padding-bottom: 40px;
  }
}
@media all and (max-width: 992px) {
  .our-service {
    padding: 50px 0;
  }
  .repair-stages {
    padding-bottom: 50px;
  }
  h2.title {
    font-size: 50px;
    line-height: 56px;
  }
  .first-screen {
    position: relative;
    padding-top: 40px;
    padding-bottom: 40px;
    &:after {
    display: none;
      .title-wrapper {
        max-width: 646px;
        width: 100%;
        margin-bottom: 40px;

      }

      }
  }
  .best {
    .best-block {
      margin: 0;
    }
    .best-item {
      max-width: 390px;
      width: 100%;
      margin: 0;
      margin-bottom: 50px;
      .number {
        margin-left: auto;
        margin-right: auto;
      }
      .title {
        text-align: center;
      }
    }
  }

  }
@media all and (max-width: 768px) {
  .repair-service {
    .first-screen {
      padding-top: 24px;
    }
  }
}
@media all and (max-width: 576px) {
  h2.title, h3.title {
    font-size: 34px;
    line-height: 46px;
    text-align: center;
  }
  .first-screen {
    /*position: relative;
    padding-top: 10px;
    padding-bottom: 10px;*/
   .list-description {
     .list-item {
       line-height: 26px;
     }
   }
    .btn {
      width: 260px;
      font-size: 14px;
    }
  }
  .banner-block {
    width: 100%;
    .count-banner {
     display: none;
    }
  }
  .wrapper-content {
    position: relative;
  }
  .repair-stages .repair-item {
      height: auto;
  }
  .best {
    padding: 50px 0;

  }

}



/*/////////////////////////////*/
/*///////repair-service////////*/
.repair-service {
  .first-screen {
    padding-top: 65px;
    padding-bottom: 73px;
    .wrapper-content {
      padding-top: 91px;
      padding-bottom: 75px;
    }
  }
  .btn {
    width: 254px;
  }
  .title-wrapper {
    width: 100%;
    max-width: 360px;
  }
  .first-screen:after {
    width: 1085px;
    height: 349px;
  }
  .banner-block {
    z-index: -1;
    position: absolute;
    max-width: 1069px;
    width: 100%;
    max-height: 440px;
    height: 100%;
    top:0;
    right: 0;
  }

  .our-advantages {
    padding-top: 114px;
    padding-bottom: 4px;
    .wrapper-content {
      padding: 0;
    }
    .title-wrapper {
      margin-bottom: 78px;
      max-width: 100%;
    }
    h3.title {
      text-align: center;
    }
    .advantages-block {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .advantages-item {
      display: flex;
      align-items: center;
      margin-bottom: 94px;
    }
    .img-block {
      width: 70px;
      height: 70px;
      margin-right: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .description {
      max-width: 320px;
      width: 100%;
      .title {
        font-size: 22px;
        font-weight: 700;
        line-height: 26px;
        margin-bottom: 10px;
      }
      .text {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
      }
    }

  }
  .good-repair, .design-service {
    padding-top: 99px;
    padding-bottom: 112px;
    background-color: #ededed;
    .wrapper-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .title-wrapper {
      max-width: 608px;
      width: 100%;
      margin-bottom: 70px;
    }
    .description-list {
      max-width: 566px;
      width: 100%;
    }
    .list-item {
      padding-left: 52px;
      margin-bottom: 18px;
      position: relative;
      font-size: 22px;
      line-height: 30px;
      font-weight: 400;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        width: 35px;
        height: 35px;
        left: 0;
        background: url(../img/svg/marker-list_img1.png) no-repeat 50% 50%;
      }
    }
    .good-repair_img {
      .item {
        max-width: 745px;
        width: 100%;
        max-height: 400px;
        height: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &:first-child {
          margin-bottom: 15px;
        }
        img {
          object-fit: contain;
          width: 100%;
        }
        .item-img {
          width: 100%;
          height: auto;
          max-width: 368px;
          max-height: 391px;

          img {
            object-fit: cover;
            height: 100%;
          }
        }
      }
    }
  }
  .apartment-repair {
    padding-top: 118px;
    padding-bottom: 70px;
    .title-wrapper {
      max-width: 100%;
      margin-bottom: 44px;
      h3.title {
        text-align: center;
      }
    }
    .wrapper-content {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
    .apartment-repair_img {
      margin-right: 146px;
    }
    .description-list {
      .list-item {
        position: relative;
        padding-left: 104px;
        margin-bottom: 18px;
        font-size: 22px;
        font-weight: 700;
        line-height: 71px;
        &:before {
          content: '';
          position: absolute;
          width: 71px;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          z-index: 3;
          height: 71px;
          border-radius: 50%;
          background-color: #ff6431;
          background-image: url("../img/svg/renovation-aprtments_marker1.png");
          background-repeat: no-repeat;
          background-position: center;
        }
      }
      .list-item:nth-child(5) {
        &:before {
          background-image: url("../img/svg/renovation-apartment_marker2.png");
        }

      }
      .list-item:nth-child(6) {
        &:before {
          background-image: url("../img/svg/renovation-apartment_marker3.png");
        }

      }
      .list-item:last-child {
        &:before {
          background-image: url("../img/svg/renovation-apartment_marker4.png");
        }

      }
    }
  }
  .standart-packages {
    padding-top: 70px;
    .title-wrapper {
      max-width: 100%;
      margin-bottom: 50px;
    }
    h3.title {
      text-align: center;
    }
    .wrapper-content {
      padding: 0 44px;
      display: flex;
      justify-content: center;
    }
    .standart-packages_item {
      position: relative;
      width: 33.3%;
      height: auto;
      background-color: rgba(0, 0, 0, 0.7);
      cursor: pointer;
      &:hover {
        background-color: rgba(0, 0, 0, 1);
        img {
          opacity: 0.3;
        }
      }
      img {
        opacity: 0.6;
      }
      .description {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 11%;
        padding-top: 12%;
        .title{
          position: relative;
          padding-bottom: 26px;
          color: #ffffff;
          font-size: 30px;
          font-weight: 700;
          margin-bottom: 14px;
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 151px;
            height: 2px;
            background-color: #ff6431;
          }
        }
        .square {
          color: #ffffff;
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 54px;
        }
        .description-list {
          color: #ffffff;
          list-style: disc;
          list-style-position: inside;
          font-size: 20px;
          font-weight: 400;
          line-height: 34px;
        }
      }
    }
  }
  .cost {
    .title-wrapper {
      max-width: 100%;
    }
  }
  .questions {
    .title-wrapper {
      max-width: 100%;
      margin-bottom: 70px;
      padding-top: 126px;
      text-align: center;
    }
    .accordion {
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;
      .accordion-item {
        margin-bottom: 20px;
      }
      .accordion-header {
        position: relative;
        height: auto;
        padding-left: 34px;
        padding-top: 21px;
        padding-bottom: 21px;
        padding-right: 60px;
        margin-bottom: 30px;
        border-radius: 10px;
        background-color: #ededed;
        font-size: 20px;
        line-height: 26px;
        font-weight: 700;
        cursor: pointer;
        &:after {
          content: '';
          position: absolute;
          font-size: 36px;
          top: 50%;
          width: 32px;
          height: 32px;
          transform: translateY(-50%);
          background: url(../img/svg/plus.png);
          right: 20px;
        }
      }
      .accordion-header.active:after {
        content: '';
        position: absolute;
        font-size: 36px;
        top: 50%;
        width: 32px;
        height: 32px;
        transform: translateY(-50%);
        background: url(../img/svg/minus.png);
        right: 20px;
      }
      .accordion-body {
        display: none;
        padding-left: 30px;
        font-size: 18px;
        font-weight: 400;
        padding-bottom: 10px;
      }
    }
  }
}
@media all and (max-width: 1385px) {
  .repair-service .good-repair .wrapper-content {
    justify-content: space-around;
  }
  .repair-service  .good-repair .good-repair_img .item {
    max-height: 100%;
  }

}
@media all and (max-width: 1345px) {
  .repair-service .apartment-repair {
      .wrapper-content {
        .apartment-repair_img {
          margin-right: 0;
          margin-bottom: 40px;
        }
        justify-content: space-around;
      }

    }

}
@media all and (max-width: 1275px) {
  .repair-service .our-advantages {
    padding-top: 50px;
    .advantages-block {
      justify-content: space-around;
    }
    .advantages-item {
      margin-bottom: 40px;
    }
  }
  .repair-service .apartment-repair {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
@media all and (max-width: 1200px) {
  .repair-service {
    .standart-packages {
      padding-top: 50px;
      .wrapper-content {
        flex-wrap: wrap;
      }
      .standart-packages_item {
        width: auto;
      }
    }
  }
  .repair-service {
    .first-screen {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
}
@media all and (max-width: 768px) {
  .repair-service  .good-repair {
    padding: 50px 0;
    .good-repair_img .item {
      justify-content: space-around;
      .item-img:first-child {
        margin-bottom: 14px;
      }
    }
  }

}
@media all and (max-width: 768px) {
  .repair-service {
    .first-screen {
      padding-top: 24px;
    }
  }
}

@media all and (max-width: 576px) {
  .repair-service {
    .title-wrapper {
      max-width: 100%;
    }
    .standart-packages {
      padding-top: 50px;
      .wrapper-content {
        padding: 0 15px;
        flex-wrap: wrap;
      }
      .standart-packages_item {
        width: auto;
        .description {
          padding-left: 5%;
          padding-top: 5%;
          .title {
            font-size: 24px;
          }
          .square {
            font-size: 18px;
            margin-bottom: 20px;
          }
          .description-list {
            line-height: 24px;
          }
        }
      }

    }
  }
}
@media all and (max-width: 576px) {
  .repair-service .apartment-repair {
    .wrapper-content {
      .apartment-repair_img {
        margin-right: 0;
        margin-bottom: 40px;
      }
      justify-content: space-around;
    }
    .description-list .list-item {
      margin-bottom: 46px;
      line-height: 30px;
      padding-left: 80px;
    }
  }

}
.repair-service.design {
  .first-screen {
    padding-bottom: 73px;
    .wrapper-content {
      padding-bottom: 0;
    }
  }
  .design-service {
    .wrapper-content {
      //justify-content: center;
    }
    background-color: transparent;
    .design-service_description {
      max-width: 595px;
      width: 100%;
      padding-left: 50px;
      padding-right: 50px;
      padding-bottom: 50px;
      padding-top: 83px;
      background-color: #ededed;
      .title-wrapper {
        margin-bottom: 58px;
      }
      h5.title {
        text-transform: uppercase;
        margin-bottom: 18px;
      }
      .description-list {
        margin-bottom: 26px;
      }

    }
    .design-service_img {
      .item {
        max-width: 900px;
        &:first-child {
          margin-bottom: 32px;
        }
        .item-img {
          max-width: 435px;
        }
      }
    }
  }
  .our-advantages {
    padding-top: 0;
    .advantages-item {
      width: 33.3%;
    }
  }
  .style {
    padding-top: 40px;
    padding-bottom: 132px;
    .style-item {
      width: 25%;
    }
    .title-wrapper {
      width: 100%;
      max-width: 570px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .best {
    background: url(../img/svg/style-best.png);
    .title-wrapper {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media all and (max-width: 1530px ) {
  .design .design-service {
    .wrapper-content {
      justify-content: space-around;
    }
    .design-service_description {
      margin-bottom: 30px;
    }
    .design-service_img .item {
      flex-wrap: nowrap;
    }
    }
}
@media all and (max-width: 1200px ) {
  .repair-service.design {
    .design-service, .first-screen {
      padding: 40px 0;
    }
    .style {
      padding: 40px 0;
    }
    .questions {
      padding-top: 40px;
      .title-wrapper {
        padding-top: 0px;
      }
    }
    .our-advantages {
      padding-top: 0;
      .advantages-item {
        width: 50%;
        justify-content: center;
      }
    }
    .style {
      .style-item {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
  }
}
@media all and (max-width: 768px ) {
  .design {
    .our-advantages {
      padding-top: 0;
      .advantages-item {
        width: 100%;
        justify-content: center;
      }
    }
    .style {
      .style-item {
        width: 100%;
        padding-bottom: 50px;
        img {
          width: 100%;
        }
      }
    }
  }
  .repair-service.design {
    .first-screen {
      padding-bottom: 40px;
    }
  }
}
@media all and (max-width: 576px ) {
  .design {
    .design-service {
      padding: 50px 0;
      .design-service_description {
        padding-top: 30px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
      }
      .design-service_img .item {
        flex-wrap: wrap;
        justify-content: space-around;
        max-height: 100%;
        .item-img:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}
.repair-service.other-service {
  .types-design {
    padding-top: 100px;
  }
  .first-screen {
    padding-bottom: 76px;
  }
  .types-design_block {

    .types-design_item {
      margin: 0 -16px;
      margin-bottom: 62px;
      h6.title {
        font-size: 40px;

        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 32px;
      }
      display: flex;
      justify-content: space-between;
      align-items: center;
      .image {
        width: 100%;
        padding: 0 16px;
        height: auto;
        max-width: 778px;
        max-height: 418px;
      }
      .description {
        padding: 0 16px;
        width: 100%;
        height: auto;
        max-width: 778px;
        max-height: 418px;
        p {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
    .types-design_item:nth-child(even) {
      flex-direction: row-reverse;
    }
  }
}
@media all and (max-width: 1200px) {
  .repair-service.other-service {
    .types-design {
      padding-top: 50px;
    }
    .types-design_block {

      .types-design_item {
        margin-bottom: 36px;
        justify-content: space-around;
        flex-wrap: wrap;
        .description {
          max-height: 100%;
        }
        .image {
          margin-bottom: 26px;
        }
        h6.title {
          font-size: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
@media all and (max-width:768px)  {
  .repair-service.other-service {
    .first-screen {
      padding-bottom: 40px;
    }
  }
}
@media all and (max-width: 576px) {
  .other-service {
    .types-design {
      padding-top: 50px;
    }
    .types-design_block {

      .types-design_item {
        .description {
          max-height: 100%;
        }

      }
    }
  }
}
.about.repair-service {
  .title-wrapper {
    max-width: 100%;
  }
  .first-screen::after {
    .title-wrapper {
      max-width: 100%;
      width: 100%;
      margin-bottom: 0;
      h2.title {
        line-height: 60px;
      }
    }
    width: 1085px;
    height: 293px;
    .wrapper-content {
    padding-top: 169px;
    padding-bottom: 122px;
  }
  }
  .about-us {
    padding-bottom: 55px;
  }
  .our-service {
    padding: 0;

    .title-wrapper {
      max-width: 210px;
      margin-bottom: 0;

      h2.title {
        line-height: 60px;
      }
    }
  }
  .best {
    background: #ededed;
    z-index: -1;
    .title-wrapper {
      max-width: 100%;
    }
    .number {
      z-index: 1;
    }
     .best-item .number::after {
       z-index: -1;
     }
  }

}
.contacts.repair-service {
  padding-bottom: 22px;
  .title-wrapper {
    max-width: 100%;
    margin-bottom: 0;
    h2.title {
      margin-bottom: 42px;
    }
    .address::after {
      position: absolute;
      top: 50%;
      left: 0;
      width: 20px;
      height: 20px;
      content: '';
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      background: url(../img/svg/maps.png) no-repeat 50% 50%;
    }
    .address, .phone-number {
      line-height: 36px;
      padding-left: 32px;
    }
    .phone2 {
      margin-bottom: 30px;
    }
  }
  .first-screen:after {
    width: 1085px;
    height: 348px;
  }
}
@media all and (max-width: 1469px){
  .about.repair-service {
    .first-screen::after {
      .title-wrapper {
        max-width: 100%;
        margin-bottom: 0;
        h2.title {
          line-height: 60px;
        }
      }
      width: 1085px;
      height: 293px;
      .wrapper-content {
        padding-top: 169px;
        padding-bottom: 122px;
      }
    }
    .about-us {
      padding-bottom: 55px;
    }
    .our-service {
      padding: 0;
      .wrapper-content {
        justify-content: space-around;
      }
      .title-wrapper {
        max-width: 100%;
        margin-bottom: 30px;
        text-align: center;

        h2.title {
          line-height: 60px;
        }
      }
    }

  }

}
@media all and (max-width: 1200px) {
  .repair-service .first-screen .wrapper-content {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .repair-service.other-service .types-design {
    padding: 40px 0;
  }
  .contacts.repair-service {
    .title-wrapper {
      max-width: 100%;
      margin-bottom: 0;
      text-align: left;
      h2.title {
        margin-bottom: 42px;
        text-align: left;
      }
      .address::after {
        position: absolute;
        top: 50%;
        left: 0;
        width: 20px;
        height: 20px;
        content: '';
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        background: url(../img/svg/maps.png) no-repeat 50% 50%;
      }
      .address, .phone-number {
        position: relative;
        line-height: 36px;
        padding-left: 32px;
        margin-left: 0;
        display: block;
      }
      .phone2 {
        margin-bottom: 30px;
      }
    }
    .first-screen:after {
      display: none;
    }
  }
}
@media all and (max-width: 992px) {

  .about.repair-service {
    .banner-block {
      img {
        height:100%;
        width: 100%;
      }
    }
  }
}

